@tailwind base;
@tailwind utilities;

#page, #timeline{
    scroll-behavior: smooth !important;

}

::selection {
    background-color: #0690f9;
    color: #fff;
}

* {
    font-family: "Montserrat", sans-serif;
}

ul.deps label::before {
    font-style: normal;
    float: left;
    font-variant: normal;
    text-rendering: auto;
    font-weight: 900;
    font-size: 12px;
    transform: scale(1.5);
    padding: 3px 8px 3px 0px;
    content: "+";
    transition: transform 0.3s ease-in-out;
}

ul.deps input[type="checkbox"]:checked + label::before {
    content: "✓";
    transform: scale(1.5) rotate(-360deg);
    transition: transform 0.3s ease-in-out;
}

ul.deps input[type="checkbox"]:checked + label {
    transition: all 0.2s;
}

body.home div.navWrapper.navWrapper.scrolled {
    background-color: #212121;
}

body.home div.navWrapper {
    background-color: transparent;
}

body.home div.navWrapper .content .links li :is(a, span):hover {
    background: #259df9;
}

.grid-areas-image {
    grid-template-areas: "stack";
}

.grid-areas-image > * {
    grid-area: stack;
}

@media screen and (max-width: 1850px) {
    .content .links li :is(a, label) {
        line-height: 25px;
        font-size: 16px;
        display: block;
        padding: 4px 10px;
        cursor: pointer;
    }
}

@media screen and (max-width: 1600px) {
    .navWrapper nav {
        justify-content: space-between;
        flex-direction: row-reverse;
    }

    .navWrapper .fastlogo {
        order: 1;
        margin-right: auto;
    }
    .navWrapper .logo {
        order: 2;
        margin-right: auto;
    }
    .navWrapper .content {
        justify-content: center;
    }
    .navWrapper .menuIcon {
        display: block;
    }
    nav .content .links {
        display: block;
        position: fixed;
        background: #212121;
        height: 100%;
        width: 100%;
        top: 70px;
        right: -100%;
        margin-left: 0;
        max-width: 350px;
        overflow-y: auto;
        padding-bottom: 100px;
        transition: all 0.3s ease;
    }
    nav .menuIcon.opened ~ .content .links {
        right: 0%;
    }
    .content .links li {
        margin: 15px 20px;
    }
    .content .links li :is(a, label, span) {
        line-height: 40px;
        font-size: 20px;
        display: block;
        padding: 8px 18px;
        cursor: pointer;
    }

    .content .links li :is(a, span) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    /* dropdown responsive code start */
    .content .links ul,
    .content .links ul ul {
        position: static;
        opacity: 1;
        visibility: visible;
        background: none;
        max-height: 0px;
        overflow: hidden;
    }
    .content .links ul li {
        margin: 7px 20px;
    }
    .content .links ul li :is(a, span) {
        font-size: 16px;
        line-height: 25px;
        border-radius: 5px !important;
    }

    .content .links ul li:hover ul {
        max-height: 100px;
        transition: all 0.3s ease;
    }
    .content .links li.has-submenu.active ul.sub-menu {
        max-height: max-content !important;
    }
}

@media screen and (max-width: 400px) {
    .content .logo a {
        font-size: 27px;
    }
}

@media screen and (max-width: 500px) {
    div.navWrapper nav .menuIcon.opened ~ .content ul.links {
        right: -20%;
    }
}

.bg-hero {
    background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.25) 0%,
            rgba(0, 0, 0, 0.25) 100%
        ),
        url(../../public/assets/hero.jpg) no-repeat center;
    background-size: cover;
}

.bg-fast {
    background: linear-gradient(0deg, rgb(2 47 114 / 47%), rgb(0 0 0 / 49%)),
        url(../../public/assets/fast.webp) no-repeat center;
}

@media screen and (max-width: 500px) {
    .bg-hero {
        background-position-x: 60%;
    }

    .bg-fast {
        background: linear-gradient(0deg, rgb(2 47 114 / 47%), rgb(0 0 0 / 49%)),
            url(../../public/assets/fast.webp) no-repeat;
        background-position: inherit;
    }
}
article.yt-lite {
    width: 100%;
}

.scrolled.navWrapper {
    padding-block: 0.8rem !important;
}
.scrolled.navWrapper :is(.logo, .fastlogo) {
    transform: scale(0.8);
    transition: transform 0.3s ease-in-out;
}

.navWrapper :is(.logo, .fastlogo) {
    transition: transform 0.3s ease-in-out;
}

.glow {
    text-shadow: 0 0 10px #fff;
}

.transition-bg {
    position: relative;
    z-index: 1;
    transition: opacity 0.2s linear;
    overflow: hidden;
}
.transition-bg::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(326deg, #c653b4 0%, #664ed8 74%);
    z-index: -1;
    transition: opacity 0.2s linear;
    opacity: 0;
}

.transition-bg:hover::before {
    opacity: 1;
}

@media screen and (max-width: 800px) {
    div.communityMarquee.marquee-container .marquee.marquee {
        display: grid !important;
        grid-template-columns: repeat(6, 200px);
    }
    div.marquee-container .overlay {
        display: none !important;
    }
}
img.black-n-white {
    filter: grayscale(100%);
    transition: filter 0.5s ease;
}
img.black-n-white:hover {
    filter: grayscale(0%);
    transition: filter 0.5s ease;
}
@media screen and (max-height: 700px) {
    .hero-section {
        min-height: 700px;
    }
}

@keyframes waving {
    0% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(-5deg);
    }
    20% {
        transform: rotate(5deg);
    }

    30% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.animate-waving {
    animation: waving 3s infinite;
    transition: 0.2s all linear;
}

@media screen and (max-width: 500px) {
    #introduction .hero-section {
        height: calc(100vh - 40px);
    }
}

body.modal-open {
    overflow: hidden;
}



html.lenis{height:auto}.lenis.lenis-smooth{scroll-behavior:auto}.lenis.lenis-smooth [data-lenis-prevent]{overscroll-behavior:contain}.lenis.lenis-stopped{overflow:hidden}.lenis.lenis-scrolling iframe{pointer-events:none}
